body {
  margin: 0;
  padding: 0;
  font-family: "MyFont";
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  overflow: auto;
}

code {
  font-family: "MyFont";
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;*/
}
html {
  overflow: hidden;
  height: 100%;
}
