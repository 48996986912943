body {
  margin: 0;
  padding: 0;
  font-family: "MyFont";
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  overflow: auto;
}

code {
  font-family: "MyFont";
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;*/
}
html {
  overflow: hidden;
  height: 100%;
}

.d3-tip {
  z-index: 100;
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
}
#all {
  z-index: 2;
  position: absolute;
}
.showLight {
  display: inline;
  display: initial;
}
.hideLight {
  display: none;
}
#light,
#pinkLight,
#redLight {
  z-index: 3;
}
.illistrator,
#SA039Mx2SA906b0,
#SA535X5XB02895,
#SA1035X4XB02879,
#SA532X1XB00118,
#CellLine,
#SA609X3X8XB03076,
#SA609X1XB00290,
#SA609X3X8MB03073 {
  z-index: 2;
  display: none;
  position: absolute;
}
.illistrator-show {
  display: inline !important;
  display: initial !important;
}
.dim-lineage {
  opacity: 0.2;
}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  z-index: 100;
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
  z-index: 100;
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
.d3-tipFitness {
  z-index: 100;
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 0, 0, 1);
  color: #fff;
  border-radius: 5px;
}
.straightNodes {
  pointer-events: all;
}
/* Creates a small triangle extender for the tooltip */
.d3-tipFitness:after {
  z-index: 100;
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 1);
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tipFitness.n:after {
  z-index: 100;
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
.svg-hidden {
  display: none;
}
.svg-override-show {
  display: all !important;
}
#dashboardTitle {
  font: "MyFont";
  font-size: 48px;
  opacity: 0.8;
  fill: #fbf7eb;
  /*  background: #ceaa39;*/
  color: #d6c17f;
  text-shadow: 1px 1px 10px #dec679, 1px 1px 10px #dcb331;
  text-align: center;
}
.timepointAxis text {
  fill: #ffffff;
  font-size: 15px;
}
.timepointAxis path,
.timepointAxis line {
  fill: none;
  opacity: 0.7;
  stroke: #ffffff;
  shape-rendering: crispEdges;
  stroke-width: 8px;
}
#g-sa1101 {
  pointer-events: all;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blueBackground {
  background: rgb(148 173 173) !important;
}
#circleTip {
  background: white;
  font-size: 12px;
  max-width: 300px;
  border-radius: 10px;
  padding: 15px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  color: #525252a6;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(https://cellmine.org/static/media/AragonST.30720522.otf) format("opentype");
}
#root {
  /*background: #20262b;*/
}
.whiteBackground {
  background: #f7f7f7 !important;
}
.blackBackground {
  background: #586773;
}
.hidden {
  display: none;
}
.overrideHide {
  display: all !important;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.highlightLink {
  stroke-width: 2;
  stroke-opacity: 1;
  stroke: yellow;
}
.highlightNode {
  r: 4;
  fill: yellow;
}
#title {
  fill: #345c70;
  -webkit-font-feature-settings: 2px;
          font-feature-settings: 2px;
  -webkit-font-kerning: 2px;
          font-kerning: 2px;
  font-size: 120px;
}
.App-header {
  background-color: #2b2a2a;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.squareInput {
  margin: 0px 15px 0px 15px;
}
.squareInput > div > input {
  height: 38px;
  border-radius: 3px !important;
}
.greyedNodes {
  opacity: 0.2 !important;
}
#profileCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
}
.gw-background-lines {
  stroke-dasharray: 0.5, 2;
  fill: none;
  stroke: black;
  shape-rendering: crispEdges;
}

#chipSelection .overlay {
  cursor: pointer;
}
.hover {
  stroke: white !important;
  fill: white;
  stroke-width: 7px;
}
.parent-hover {
  stroke: white !important;
  stroke-width: 7px !important;
  -webkit-filter: url(#glow);
          filter: url(#glow);
}
.visible {
  stroke: white;
  stroke-width: 7px;
}
.choosenNode {
  fill: white !important;
  stroke: white !important;
}
.chosenNodeParent {
  -webkit-filter: url(#glow);
          filter: url(#glow);
  stroke: white !important;
  stroke-width: 4 !important;
}
.choosenLink {
  -webkit-filter: url(#glow);
          filter: url(#glow);
  stroke: white !important;
  stroke-width: 10 !important;
}
.parent-project {
  fill: #e6e6e6;
  stroke: white;
  stroke-width: 7px;
  opacity: 1 !important;
  -webkit-filter: url(#innerRingGlow) !important;
          filter: url(#innerRingGlow) !important;
}
.link-hover {
  stroke-width: 20px !important;
}
.chipLegendAxis line,
.chipLegendAxis path {
  stroke: grey;
}

.chipLegendAxis text {
  fill: grey;
}
.directLabel {
  font-size: 100px;
  font-weight: bold;
  fill: white;
}
.hidePanel {
  display: none;
}
.labelsOutterRingHover {
  radius: 80 !important;
  font-weight: "bold" !important;
  font-size: 180px !important;
  stroke-width: 10 !important;
}
.jiraTicketLabelsOutterRing {
  stroke: white;
  stroke-width: 5;
  fill: none;
  font-size: 150px;
}

.selectedText {
  font-weight: bold !important;
  font-size: 175px !important;
}
#medianLine {
  -webkit-transform: translate(0px, 100px);
          transform: translate(0px, 100px);
}
.highlight0 {
  stroke: #f1c023 !important;
  stroke-width: 5px;
  fill: #f1c023;
}
.highlight1 {
  stroke: #bc4746 !important;
  fill: #bc4746;
}
.highlight2 {
  stroke: #95d2dc !important;
  fill: #95d2dc;
}
.jiraTicketlabels {
  font-family: "Lucida Console", Monaco, monospace;
  fill: white;
  font-weight: 100;
  font-size: 120px;
  white-space: pre;
  opacity: 1;
}
.panelLabel {
  font-family: "Lucida Console", Monaco, monospace;
  fill: black;
  font-size: 125px;
  white-space: pre;
  opacity: 1;
}
.filterDotsOutterCircles {
  fill: none !important;
  stroke: white !important;
  stroke-width: 1px;
}
.diagramOutterCircles {
  fill: none !important;
  stroke: black !important;
  stroke-width: 10px;
}
.legendTitles,
.legendDescription {
  font-family: "Lucida Console", Monaco, monospace;
  fill: black;
  font-weight: normal;
  white-space: pre;
  opacity: 1;
  text-align: center;
  font-size: 100px;
}
.jiraLabel {
  font-family: arial;
  fill: black;
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-anchor: middle;
}
#chipTip.tip span:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black;
  left: -8px;

  top: 7px;
}
#categoryTip {
  margin: 5px;
  border: 1px solid #2e3131;
  border-radius: 5px;
  background: #2e3131;
}
.d3-tip {
  line-height: 1;
  padding: 6px;
  background: #ffffff;
  color: #000;
  border-radius: 4px;
  font-size: 12px;
}
#minimapBrush g.brush > .handle,
#chipSelection g.brush > .handle {
  display: none;
}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: white;
  position: absolute;
  pointer-events: none;
}
/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}
#violinTip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: white;
  position: absolute;
  pointer-events: none;
}
/* Style northward tooltips differently */
#violinTip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}
#violinTip {
  stroke: black;
  stroke-width: 2;
  background: #c2c0bf;

  background-color: #c2c0bf;
  opacity: 0.1;
}

.hoverViolin {
  stroke-width: 10px;
}
#categoryChipTip {
  fill: #ffffff;
  color: #ffffff;
  stroke-width: 2;

  border: 1px solid #2e3131;
  border-radius: 5px;
  background: #2e3131;
}
/* Northward tooltips */
#categoryChipTip.n:after {
  content: "\25BC";

  color: #2e3131;
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}
.hoveredCategorySquare {
  stroke: black;
  stroke-width: 2;
}
@-webkit-keyframes neon {
  from {
    text-shadow: 0 0 2px rgba(255, 255, 225, 0.92),
      0 0 15px rgba(255, 255, 255, 0.34), 0 0 12px rgba(255, 255, 255, 0.52),
      0 0 10px rgba(255, 255, 255, 0.92), 0 0 34px rgba(255, 255, 255, 0.78),
      0 0 20px rgba(255, 255, 255, 0.92);
  }
  to {
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.98),
      0 0 30px rgba(255, 228, 225, 0.42), 0 0 12px rgba(255, 255, 255, 0.58),
      0 0 22px rgba(255, 132, 255, 0.84), 0 0 20px rgba(255, 255, 255, 0.88),
      0 0 45px rgba(255, 255, 255, 1);
  }
}
@keyframes neon {
  from {
    text-shadow: 0 0 2px rgba(255, 255, 225, 0.92),
      0 0 15px rgba(255, 255, 255, 0.34), 0 0 12px rgba(255, 255, 255, 0.52),
      0 0 10px rgba(255, 255, 255, 0.92), 0 0 34px rgba(255, 255, 255, 0.78),
      0 0 20px rgba(255, 255, 255, 0.92);
  }
  to {
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.98),
      0 0 30px rgba(255, 228, 225, 0.42), 0 0 12px rgba(255, 255, 255, 0.58),
      0 0 22px rgba(255, 132, 255, 0.84), 0 0 20px rgba(255, 255, 255, 0.88),
      0 0 45px rgba(255, 255, 255, 1);
  }
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

