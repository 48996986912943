.App {
  text-align: center;
}
@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(./theme/AragonST.otf) format("opentype");
}
#root {
  /*background: #20262b;*/
}
.whiteBackground {
  background: #f7f7f7 !important;
}
.blackBackground {
  background: #586773;
}
.hidden {
  display: none;
}
.overrideHide {
  display: all !important;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.highlightLink {
  stroke-width: 2;
  stroke-opacity: 1;
  stroke: yellow;
}
.highlightNode {
  r: 4;
  fill: yellow;
}
#title {
  fill: #345c70;
  font-kerning: 2px;
  font-size: 120px;
}
.App-header {
  background-color: #2b2a2a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.squareInput {
  margin: 0px 15px 0px 15px;
}
.squareInput > div > input {
  height: 38px;
  border-radius: 3px !important;
}
.greyedNodes {
  opacity: 0.2 !important;
}
#profileCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
}
.gw-background-lines {
  stroke-dasharray: 0.5, 2;
  fill: none;
  stroke: black;
  shape-rendering: crispEdges;
}

#chipSelection .overlay {
  cursor: pointer;
}
.hover {
  stroke: white !important;
  fill: white;
  stroke-width: 7px;
}
.parent-hover {
  stroke: white !important;
  stroke-width: 7px !important;
  filter: url(#glow);
}
.visible {
  stroke: white;
  stroke-width: 7px;
}
.choosenNode {
  fill: white !important;
  stroke: white !important;
}
.chosenNodeParent {
  filter: url(#glow);
  stroke: white !important;
  stroke-width: 4 !important;
}
.choosenLink {
  filter: url(#glow);
  stroke: white !important;
  stroke-width: 10 !important;
}
.parent-project {
  fill: #e6e6e6;
  stroke: white;
  stroke-width: 7px;
  opacity: 1 !important;
  filter: url(#innerRingGlow) !important;
}
.link-hover {
  stroke-width: 20px !important;
}
.chipLegendAxis line,
.chipLegendAxis path {
  stroke: grey;
}

.chipLegendAxis text {
  fill: grey;
}
.directLabel {
  font-size: 100px;
  font-weight: bold;
  fill: white;
}
.hidePanel {
  display: none;
}
.labelsOutterRingHover {
  radius: 80 !important;
  font-weight: "bold" !important;
  font-size: 180px !important;
  stroke-width: 10 !important;
}
.jiraTicketLabelsOutterRing {
  stroke: white;
  stroke-width: 5;
  fill: none;
  font-size: 150px;
}

.selectedText {
  font-weight: bold !important;
  font-size: 175px !important;
}
#medianLine {
  transform: translate(0px, 100px);
}
.highlight0 {
  stroke: #f1c023 !important;
  stroke-width: 5px;
  fill: #f1c023;
}
.highlight1 {
  stroke: #bc4746 !important;
  fill: #bc4746;
}
.highlight2 {
  stroke: #95d2dc !important;
  fill: #95d2dc;
}
.jiraTicketlabels {
  font-family: "Lucida Console", Monaco, monospace;
  fill: white;
  font-weight: 100;
  font-size: 120px;
  white-space: pre;
  opacity: 1;
}
.panelLabel {
  font-family: "Lucida Console", Monaco, monospace;
  fill: black;
  font-size: 125px;
  white-space: pre;
  opacity: 1;
}
.filterDotsOutterCircles {
  fill: none !important;
  stroke: white !important;
  stroke-width: 1px;
}
.diagramOutterCircles {
  fill: none !important;
  stroke: black !important;
  stroke-width: 10px;
}
.legendTitles,
.legendDescription {
  font-family: "Lucida Console", Monaco, monospace;
  fill: black;
  font-weight: normal;
  white-space: pre;
  opacity: 1;
  text-align: center;
  font-size: 100px;
}
.jiraLabel {
  font-family: arial;
  fill: black;
  color: white;
  font-size: 45px;
  font-weight: bold;
  text-anchor: middle;
}
#chipTip.tip span:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black;
  left: -8px;

  top: 7px;
}
#categoryTip {
  margin: 5px;
  border: 1px solid #2e3131;
  border-radius: 5px;
  background: #2e3131;
}
.d3-tip {
  line-height: 1;
  padding: 6px;
  background: #ffffff;
  color: #000;
  border-radius: 4px;
  font-size: 12px;
}
#minimapBrush g.brush > .handle,
#chipSelection g.brush > .handle {
  display: none;
}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: white;
  position: absolute;
  pointer-events: none;
}
/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}
#violinTip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: white;
  position: absolute;
  pointer-events: none;
}
/* Style northward tooltips differently */
#violinTip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}
#violinTip {
  stroke: black;
  stroke-width: 2;
  background: #c2c0bf;

  background-color: #c2c0bf;
  opacity: 0.1;
}

.hoverViolin {
  stroke-width: 10px;
}
#categoryChipTip {
  fill: #ffffff;
  color: #ffffff;
  stroke-width: 2;

  border: 1px solid #2e3131;
  border-radius: 5px;
  background: #2e3131;
}
/* Northward tooltips */
#categoryChipTip.n:after {
  content: "\25BC";

  color: #2e3131;
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}
.hoveredCategorySquare {
  stroke: black;
  stroke-width: 2;
}
@keyframes neon {
  from {
    text-shadow: 0 0 2px rgba(255, 255, 225, 0.92),
      0 0 15px rgba(255, 255, 255, 0.34), 0 0 12px rgba(255, 255, 255, 0.52),
      0 0 10px rgba(255, 255, 255, 0.92), 0 0 34px rgba(255, 255, 255, 0.78),
      0 0 20px rgba(255, 255, 255, 0.92);
  }
  to {
    text-shadow: 0 0 2px rgba(202, 228, 225, 0.98),
      0 0 30px rgba(255, 228, 225, 0.42), 0 0 12px rgba(255, 255, 255, 0.58),
      0 0 22px rgba(255, 132, 255, 0.84), 0 0 20px rgba(255, 255, 255, 0.88),
      0 0 45px rgba(255, 255, 255, 1);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
