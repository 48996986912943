body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blueBackground {
  background: rgb(148 173 173) !important;
}
#circleTip {
  background: white;
  font-size: 12px;
  max-width: 300px;
  border-radius: 10px;
  padding: 15px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  color: #525252a6;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
